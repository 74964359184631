html {
  scroll-behavior: smooth;
}
.leaflet-container {
    width: 100wh; 
    height: 400px;
  }
.register-header {
  padding-top:20px;
  text-align: center;
  color:#fff;
  z-index:1000;
}
.register-header h1 {
  font-size:40px;
  font-weight:bold;
  padding-bottom: 20px;
}

.register-header h3 {
  font-size:20px;
  font-weight:bold;
  padding:10px 0 30px 0;
}
.logo_img {
  max-width: 100%;
  height: auto;
  width: 14rem;
  position :absolute;
  top: 100px;
  left:50%;
  transform: translate(-50%, -50%);
}

.wizard_btn {
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
}
.back_btn {
  border: 1px solid #fb4141;
  color: #FB4141;
}

.next_btn {
  color: #fff;
  background: #FB4141;
  float: right;
}
.preview_btn {
  color: #fff;
  background: #0EA5E9;
  float: right;
}


/* register and login page */

.bgGray {
  background: #4B5462;
}
.blueBg {
  background : #0057B7; 
}
.blueText {
  color : #0057B7;
}
.blueTextDark {
  color: #011e77;
}
.blueDarkBg {
  background: #011e77;
}
.blueDarkBgBorder {
  border: 1px solid #011e77;
}
.blueBorder {
  border: 1px solid #0057B7;
}