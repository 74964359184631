.counter{
    color: #075985;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
    width: 150px;
    height: 150px;
    padding: 24px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
.counter:before,
.counter:after{
    content: '';
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,0.1) inset;
    position: absolute;
    left: 0;
    top: 15px;
    bottom: 0;
    right: 7px;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 98% 90%, 15% 100%);
}
.counter:after{
    background-color: #075985;
    top: 0;
    right: 0;
    z-index: -2;    
    clip-path: polygon(10% 0, 100% 10%, 100% 85%, 0% 100%);
}
.counter .counter-icon{
    font-size: 20px;
    margin: 0 0 3px;
}
.counter .counter-value{
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 2px;
    display: block;
}
.counter h3{
    color: #777;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
}